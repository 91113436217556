html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  padding: 0;
}

/*sidebar width == margin-left of main*/

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 100px;
  padding-left: 0px;
  padding-right: 0px;
  transition: width 0.3s ease-out;
  background: #e6f1ff;
  color: #303c55;
}

.project_side {
  height: 300px;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  background-color: #111;
  background: #e6f1ff;
  color: #303c55;
}

.ps_about {
  width: 350px;
  padding: 10px;
}

.centered {
  margin: auto;
  padding: 10px;
}

.sb_img {
  background: #1b3d50;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.main {
  /* padding-left: 0; Same as the width of the sidenav */
  padding: 100px 40px;
  align-items: center;
  /* padding-top: 100px; */
  position: relative;
  transition: padding-left 0.3s ease-out;
  margin: auto;
  max-width: 100%;
  width: 1500px;
}

.blog_area {
  align-items: center;
  position: relative;
  /* width: 1500px; */
  padding: 20px;
}

.markdown {
  font-size: 140%;
  text-align: justify;
  justify-content: center;
  padding: 45px;
}

.headerText {
  text-transform: uppercase;
  font-weight: 550;
}

.scroll-with-offset {
  padding-top: 100px;
  margin-bottom: -100px;
}

.sidebar_text {
  font-size: "100%";
}

@media only screen and (min-width: 1280px) {
  .sidebar {
    width: 400px;
    padding-left: 0;
    padding-right: 0;
  }
  .main {
    padding-left: 440px;
  }
}
